<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/5
  * @BSD
-->
<template>
  <div class="page-wrap">
    <topBarBlack />
  </div>
</template>

<script>
  import topBarBlack from '../../components/top-bar-black'
  export default {
    name: "chance",
    components: {
      topBarBlack
    }
  }
</script>

<style scoped>

</style>
